<!--
  PACKAGE_NAME : src\pages\ai\llm\work-config
  FILE_NAME : evaluation-prompt-copy-add
  AUTHOR : hpmoon
  DATE : 2024-09-13
  DESCRIPTION : 운영관리 > LLM > LLM Tester > 프로젝트 작업 설정 > 평가 프롬프트 > 프롬프트 새로 저장
-->
<template>
  <DxPopup
      :show-title="true"
      :title="modal.title"
      :min-width="modal.minWidth"
      :width="modal.width"
      :min-height="modal.minHeight"
      :height="modal.height"
      :drag-enabled="modal.dragEnabled"
      :resize-enabled="modal.resizeEnabled"
      :close-on-outside-click="modal.closeOnOutsideClick"
      :show-close-button="modal.showCloseButton"
      :visible="isOpen"
      @hiding="closeModal"
  >
    <template #content>
      <div>
        <table class="table_form line-bin">
          <colgroup>
            <col style="width: 130px" />
            <col style="width: auto" />
          </colgroup>

          <tbody>
          <tr>
            <th scope="row">
              <label for="label5">{{ $_msgContents('COMPONENTS.TITLE', {defaultValue: '제목'}) }} <span class="icon_require">필수항목</span></label>
            </th>
            <td>
              <DxTextBox
                  v-model="formData.name"
                  :max-length="limitNumberTexts.maxLengths.name"
                  :styling-mode="config.stylingMode"
                  class="mar_ri10"
                  :width="200"
              >
                <DxValidator validation-group="validationGroupName">
                  <DxAsyncRule :validation-callback="checkValidName" :message="$_msgContents('COMMON.MESSAGE.NOT_DUPLICATE_VALUE_IS', { value: $_msgContents('COMPONENTS.TITLE', { defaultValue: '제목' }) })" />
                  <DxRequiredRule :message="$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: $_msgContents('COMPONENTS.TITLE', {defaultValue: '제목'}) })" />
                </DxValidator>
              </DxTextBox>
              <span>
              {{
                  limitNumberTexts.textLengths.name
                      ? limitNumberTexts.textLengths.name
                      : formData.name
                          ? formData.name.length
                          : 0
                }}
            </span>/{{ limitNumberTexts.maxLengths.name }}
            </td>
          </tr>

          <tr>
            <td colspan="2" style="text-align: center">
              {{ $_msgContents('LLM.MESSAGE.NEW_PROMPT_ADD_ALERT', { defaultValue: '선택한 프롬프트를 수정하지 않고 새로운 프롬프트로 생성됩니다' }) }}
              <br>
              {{ $_msgContents('LLM.MESSAGE.NEW_PROMPT_NAME_ALERT', { defaultValue: '새로운 프롬프트의 제목을 입력하세요.' }) }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>

    <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="true"
        :options="{
            elementAttr: { class: 'default filled txt_S medium' },
            text: this.$_msgContents('COMPONENTS.SAVE', { defaultValue: '저장' }),
            width: '120',
            height: '40',
            useSubmitBehavior: true,
            validationGroup: 'validationGroupName',
            onClick: saveModal,
          }"
    />
    <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="true"
        :options="{
            elementAttr: { class: 'white filled txt_S medium' },
            text: this.$_msgContents('COMPONENTS.CANCEL', { defaultValue: '취소' }),
            width: '120',
            height: '40',
            onClick: closeModal,
          }"
    />
  </DxPopup>
</template>

<script>
import {DxPopup, DxToolbarItem} from 'devextreme-vue/popup';
import { DxTextBox } from 'devextreme-vue/text-box';
import {DxValidator, DxRequiredRule, DxAsyncRule} from 'devextreme-vue/validator';

export default {
  components: {
    DxPopup,
    DxToolbarItem,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxAsyncRule,
  },

  props: {
    isOpen: Boolean,
    project_id: String,
    content: String
  },

  watch: {
    formData: {
      handler(val) {
        let filterData = val;
        if (filterData) {
          this.$emit('input', filterData);
        }
      },
      deep: true,
    },
  },

  data() {
    return {
      modal: {
        title: this.$_msgContents('LLM.WORD.EVALUATION_PROMPT', { defaultValue: '평가 프롬프트' }) + ' '
              +this.$_msgContents('LLM.WORD.COPY_ADD', { defaultValue: '새로 저장' }),
        minWidth: '500',
        width: '500',
        minHeight: '250',
        height: '250',
        dragEnabled: true,
        resizeEnabled: true,
        showCloseButton: true,
        closeOnOutsideClick: false,
      },
      config: {
        stylingMode: 'outlined', //[outlined, filled, underlined]
      },
      formData: {
        project_id: null,
        name: null,
        content: null,
      },
      limitNumberTexts: {
        textLengths: {},
        maxLengths: {
          name: 30,
          content: 2000,
        },
      },
    };
  },

  computed: {
  },

  methods: {
    /** @description 모달 저장 */
    async saveModal(e) {
      if (!e.validationGroup.validate().isValid) {
        e.validationGroup.validate().validators.forEach((validator) => {
          validator.$element().addClass("dx-state-focused");
        });
        return;
      }

      if (!await this.checkValidName(e)) return false;

      if (await this.$_Confirm(this.$_msgContents('LLM.MESSAGE.SAVE_EVALUATION_PROMPT', { defaultValue: '평가 프롬프트를 저장하시겠습니까?' }))) {
        let payload = {
          actionname: 'LLM_EVALUATION_PROMPT_SAVE',
          data: this.formData,
          loading: true,
        };

        let res = await this.CALL_LLM_API(payload);
        if (res.status === 200) {
          this.$emit('saveModal');
        } else {
          this.$_Msg(this.$_msgContents('CMN_ERROR'), { icon: 'error' });
        }
      }
    },

    /** @description 제목 중복 체크 메서드 */
    async checkValidName(e) {
      let payload = {
        actionname: 'LLM_EVALUATION_PROMPT_EXISTS',
        data: {
          project_id: this.formData.project_id,
          name: this.formData.name,
        },
        loading: false,
      }
      let res = await this.CALL_LLM_API(payload);
      if (res.status === 200) {
        if (res.data) {
          if (e.validationGroup) e.validationGroup.validate().validators[0].$element().addClass("dx-state-focused");
          if (e.validator) e.validator?.$element().addClass("dx-state-focused");
        }
        return !res.data;
      } else {
        this.$_Msg(this.$_msgContents('CMN_ERROR'), { icon: 'error' });
        return false;
      }
    },

    /** @description 모달 닫기 */
    closeModal() {
      this.$emit('closeModal');
    },

    createdData() {
      this.formData.project_id = this.project_id;
      this.formData.content = this.content;
    },

    /** @description 라이프사이클 mounted 호출되는 메서드 */
    mountedData() {

    },
  },

  created() {
    this.createdData();
  },

  mounted() {
    this.mountedData();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .dx-placeholder {
    padding-left: 20px;
  }
  .item-context > div {
    padding-left: 10px;
    float: left;
  }
}
</style>